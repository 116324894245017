import React from 'react';
import "./index.css";

function Contact(){
    return(
        <div id ="contact" className="brownish">
            <form name="contact" method="POST">
                <input type="hidden" name="form-name" value="contact"/>
                <p>
                    <label>Your Name: <input type="text" name="name" /></label>   
                </p>
                <p>
                    <label>Your Email: <input type="email" name="email" /></label>
                </p>
                <p>
                    <label>Message: <textarea name="message"></textarea></label>
                </p>
                <p>
                    <button type="submit">Send</button>
                </p>
            </form>

            <a className ="mailbutton coral" href ="mailto: Dalton.earl@outlook.com">Email Me!</a>
        </div>
    )
}
export default Contact