import React from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Billboard from './Components/Billboard';
import Blog from './Components/Blog';
import './App.css';

function App() {
  return (
    <div>
      <Header/>
      <div className="display">
      <Billboard/>
      <Blog/>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
