import React from 'react';
import AboutMe from '../AboutMe';
import Contact from '../Contact';
import Projects from '../Projects';
import './index.css';

function Billboard(){
    return (
        <div className="billboard">
        <div className = "slider">
            <div className ="slides brownish">
                <AboutMe/>
                <Contact/>
                <Projects/>
                    
                
            </div>
            <a className = "navbutton coral" href="#about">About</a>
            <a className = "navbutton coral" href="#contact">Contact</a>
            <a className = "navbutton coral" href="#projects">Projects</a>
        </div>
        </div>
    )
}

export default Billboard