import React from 'react';
import pictureofme from './pictureofme.jpg';
import './index.css';



function AboutMe(){
    return(
        <div id ="about">
            <img id = "pictureofme" src= {pictureofme} alt =""></img>
            <div className ="aboutcard offwhite">
                <h1>What I like</h1>
                <p>Front-end web development Open source software Consumer electronics 
                    Computers </p>
            </div>
            <div className ="aboutcard offwhite">
                <h1>What I know</h1>
                <p>HTML CSS JavaScript MySql Bootstrap Materialze React MongoDB</p>
            </div>
            <div className ="aboutcard offwhite">
                <h1>What I'm Looking for</h1>
                <p>An place to get my foot in the door to start a career in web development</p>
            </div>
        </div>
    )
}
export default AboutMe