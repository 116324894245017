import React from 'react';
import './index.css';

function Header(){
    return (
        
        <header className="header greenish">
            <a href="#about" className="home" >Dalton Earl</a>
            <div className ="header-right">
            <a href="#projects">Projects</a>
            <a href="#contact">Contact me</a>
            </div>
        </header>
    )
}

export default Header