import React from 'react';
import "./index.css";

function Projects(){
    return(
        <div id = "projects">
            My Projects my dudes
        </div>
    )
}
export default Projects